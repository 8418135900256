<template>
  <div>
    <AppLayoutHeader :hide-hamburger-button="true" />
    <div class="form-container">
      <div class="sign-up-sub-header">
        <h2 class="ml-md-5">{{ $t("common.memberRegistration") }}</h2>
      </div>
      <div class="ma-4">
        <v-form ref="form">
          <div class="des-form-section">
            <div>
              <v-alert type="error" text="white">
                当ページはVacation Styleの新規登録ページです。
                マイウィーク・フレックスポイント・シェアリングポイント会員様の登録画面ではございません。<br>
                ご注意ください。
              </v-alert>
            </div>
            <div v-if="!isCcAvailable" class="--c-red px-2 text-center">
              <format-span :value="ccMaintenanceMessage" />
            </div>
            <h2 class="ml-md-5 text-center text-md-left">
              {{ $t("registerPage.registerWithEmail") }}
            </h2>
            <span class="note1">
              {{ $t("registerPage.registerDesContent") }}
            </span>
          </div>

          <div
            class="shadow-smoke bg-w-silver form-section px-5 py-6 my-8 d-md-flex flex-md-column"
          >
            <div class="radio-container">
              <span class="label-radio-main">{{ $t("registerPage.memberType") }}</span>
              <v-radio-group
                row
                v-model="signUpForm.memberType"
                class="radio-sign-up"
              >
                <v-radio value="Individual">
                  <template v-slot:label>
                    <span class="label-radio-item">{{ $t("registerPage.individual") }}</span>
                  </template>
                </v-radio>
                <v-radio value="Corporation" class="ml-10">
                  <template v-slot:label>
                    <span class="label-radio-item">{{ $t("registerPage.corporation") }}</span>
                  </template>
                </v-radio>
              </v-radio-group>
            </div>
            <span class="custom-input-label">
              {{ $t("registerPage.email") }}
              <v-chip
                class="ml-3"
                color="var(--color__pink)"
                label
                text-color="var(--color__white)"
              >
                {{ $t("registerPage.required") }}
              </v-chip>
            </span>
            <v-text-field
              class="mt-2 --border"
              v-model="signUpForm.email"
              :rules="[$rules.required, $rules.email]"
              outlined
              required
            />

            <span class="custom-input-label">
              {{ $t("registerPage.emailConfirmed") }}
              <v-chip
                class="ml-2"
                color="var(--color__pink)"
                label
                text-color="var(--color__white)"
              >
                {{ $t("registerPage.required") }}
              </v-chip>
            </span>
            <v-text-field
              class="mt-2 --border"
              v-model="signUpForm.emailConfirmed"
              :rules="rules.emailConfirmed"
              outlined
              required
            />

            <div class="d-md-flex flex-column align-md-center">
              <a
                href="https://www.tokyu-vacations.com/privacypolicy/"
                class="d-inline-flex align-baseline ml-2"
                target="_blank"
              >
                <v-img
                  class="mr-3"
                  width="14"
                  height="14"
                  :src="iconPolicy"
                ></v-img>
                {{ $t("registerPage.policy") }}
              </a>
              <v-radio-group
                row
                v-model="signUpForm.agreePolicy"
                :rules="[$rules.required]"
              >
                <v-radio value="agree">
                  <template v-slot:label>
                    <span class="label-radio-item">
                      {{ $t("registerPage.agreePolicy") }}</span
                    >
                  </template>
                </v-radio>
              </v-radio-group>
            </div>
          </div>
        </v-form>

        <div class="my-4 bg-white --dark pa-3 text-medium-szm --w-regular">
          <h3>※Vacation Style入会における注意点</h3>
          <br>
          <ul>
            <li>
            一部施設（※）につきまして、ホテル内の一部が東急バケーションズ客室となります。<br>
            客室数や部屋タイプなど詳細につきましては施設一覧ページからご確認ください。<br>
            ※札幌・伊豆今井浜・金沢・東京 二子玉川・沖縄・宮古島・Style Select 京都洛龍菴・富士山 三島・大阪 御堂筋の東急バケーションズ各施設<br>
            </li>
            <li class="mt-2">
              ご利用の施設・時期を固定した利用権をご契約されている方や、これまでに販売した利用権でVacation Styleの予約開始時期より先にご予約される方がいらっしゃるため、ご予約開始時点で既に満室となる可能性がございます。<br>
            </li>
            <li class="mt-2">
              Vacation Styleの入会によって宿泊予約が確約されるものではなく、宿泊予約を都度行い、客室に空室があり、
              東急株式会社との宿泊契約が成約された時点で確約となりますので、東急株式会社が定める各種規約（Vacation Style会員規約、東急バケーションズ宿泊約款等）を必ずご確認のうえ、お手続きください。
            </li>
            <li class="mt-2">
              Vacation Styleの会員宿泊価格は、予約受付開始後において、同施設・同日程の場合であっても需要に応じて変更される場合があります。なお、お客様ごとの宿泊価格は、ご予約時における東急株式会社との宿泊契約が成約された時点での価格が適用されます。
              <br>※ご予約時点での会員宿泊価格については「Vacation Style会員ページ」にてご確認ください。
            </li>
          </ul>
        </div>

        <div class="d-flex flex-column align-center">
          <v-btn
            :disabled="!isCcAvailable"
            elevation="0"
            class="btn-icon shadow-white mb-5 text-heading"
            @click="commit()"
            block
            rounded
            color="btn-grad--orange"
            x-large
          >
            <span class="text-center w-100">{{ $t('buttons.sending') }}</span>
            <v-icon color="var(--color__white)">mdi-chevron-right-circle-outline</v-icon>
          </v-btn>
          <span class="label-radio-item" v-html="$t('registerPage.submitDescription')" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppLayoutHeader from '@/layout/AppLayout/components/AppLayoutHeader'
import { creditCardMixin } from '@/mixins/credit-card.mixin'

export default {
  name: 'Register',
  mixins: [creditCardMixin],
  components: {
    AppLayoutHeader
  },
  data () {
    return {
      signUpForm: {
        confirmRule: false,
        memberType: 'Individual',
        email: '',
        emailConfirmed: '',
        agreePolicy: false
      },
      rules: {
        emailConfirmed: [
          v => v === this.signUpForm.email || this.$t('rules.emailNotMatch')
        ]
      }
    }
  },
  methods: {
    async commit () {
      if (this.$refs.form.validate()) {
        await this.$doLoading(async () => {
          await this.$showGqlError(async () => {
            await this.$store.dispatch('register', {
              apollo: this.$apollo,
              email: this.signUpForm.email,
              isCompany: this.signUpForm.memberType !== 'Individual',
              promoCode: this.promoCodeFromUrl
            })

            this.$toast.open(this.$t('authPage.pleaseCheckEmailAndConfirm').toString())
          })
        })
      }
    }
  },
  computed: {
    registerImgSrc () {
      return require('@/assets/images/register-banner.png')
    },
    iconPolicy () {
      return require('@/assets/images/icon-policy.png')
    },
    promoCodeFromUrl () {
      return this.$route.query.code
    }
  }
}
</script>

<style lang="scss" scoped>
@use '../../assets/styles/modules/variables-scss'as v;

h2 {
  font-size: var(--font-size__heading);
  color: var(--color__primary);
  font-weight: var(--font-weight__bold) !important;
  line-height: 3;
}

.sign-up-sub-header {
  background-color: var(--color__white);
  text-align: center;

  @media (min-width: v.$breakpoint__md) {
    text-align: left;
  }
}

div.shadow-smoke {
  .v-card__title {
    background-color: var(--color__primary);
    border-radius: 5px 5px 0 0;
    color: var(--color__white);
  }

  .v-card__text {
    text-align: left;
  }
}

.note1 {
  font-size: var(--font-size__title--szm);
  color: var(--color__dark);
  font-weight: var(--font-weight__medium);
}

@media (max-width: v.$breakpoint__md) {
  .v-image {
    width: 173px;
    max-width: 180px;
  }
}

.note2 {
  font-size: var(--font-size__normal);
  color: var(--color__dark);
  font-weight: normal;
}

::v-deep {
  .check-thin {
    label.v-label {
      font-size: 15px;
      color: var(--color__dark);
    }

    .form-section {
      background-color: var(--color__smoke);
    }
  }

  .radio-container {
    .radio-sign-up {
      .v-input--selection-controls__input:hover .v-input--selection-controls__ripple::before {
        background-color: transparent;
        box-shadow: none;
      }
    }
  }
}

.radio-container {
  .label-radio-main {
    font-size: var(--font-size__title);
    color: var(--color__dark);
    font-weight: var(--font-weight__medium);
  }

  .radio-sign-up {
    .label-radio-item {
      font-size: var(--font-size__medium--szm);
      color: var(--color__dark);
      font-weight: normal;
    }
  }
}
</style>
