import { checkNumber } from '@/utils/validate'

export const creditCardMixin = {
  async mounted () {
    // load js file for tokenizing the credit card info.
    const gmoScript = document.createElement('script')
    gmoScript.setAttribute('src', process.env.VUE_APP_GMO_TOKENIZE_JS_URL)
    document.head.appendChild(gmoScript)
    await this.$store.dispatch('getCcStatus')
  },
  data () {
    return {
      creditCardRules: {
        cardHolderName: [
          v => !!v || this.$t('rules.isRequired'),
          v => v?.trim().length < 25 || this.$t('rules.invalid'),
          v => !v || /^[a-zA-Z ]*$/.test(v) || this.$t('rules.cardHolderAlphabet')
        ],
        cardNumber: [
          v => !!v || this.$t('rules.isRequired'),
          v => (checkNumber(v) && v?.length === 4) || this.$t('rules.invalid')
        ],
        cardNumber4: [
          v => !!v || this.$t('rules.isRequired'),
          v => (checkNumber(v) && v?.length >= 2 && v?.length <= 4) || this.$t('rules.invalid')
        ],
        securityCode: [
          v => !!v || this.$t('rules.isRequired'),
          v => v?.trim().length === 3 || v?.trim().length === 4 || this.$t('rules.invalid')
        ]
      }
    }
  },
  computed: {
    gmoShopId () {
      return this.$store.getters.gmoShopId
    },
    isCcAvailable() {
      return this.$store.getters.isCcavailable
    },
    ccMaintenanceMessage() {
      return this.$store.getters.ccMaintenanceMessage
    }
  },
  methods: {
    setupGmo () {
      if (this.gmo) return
      this.gmo = window.Multipayment
      this.gmo.init(this.gmoShopId)
    },
    async tokenize (cardHolderName, cardNumber, expires, securityCode) {
      await this.$store.dispatch('getCcStatus')
      if (this.isCcAvailable) {
        return new Promise((resolve, reject) => {
          window.gmoResponseHandler = function gmoResponseHandler (response) {
            if (response.resultCode !== '000') {
              // show error message
              reject(response)
            } else {
              const token = response.tokenObject.token
              resolve(token)
            }
          }

          this.gmo.getToken({
            holdername: cardHolderName,
            cardno: cardNumber,
            expire: expires, // expiry in format 'YYYYMM'
            securitycode: securityCode
          }, 'gmoResponseHandler')
        })
      }
    }
  }
}
